import React, { useState, useEffect, Component, useRef } from 'react';
import { Container, Row, Col, Card, Form, Button } from "react-bootstrap";
import axios from 'axios';
import "bootstrap/dist/css/bootstrap.min.css";
import './Post.css';
import 'react-image-lightbox/style.css';
import parse from 'html-react-parser';
import { useParams } from 'react-router-dom';
import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import { Link } from 'react-router-dom';
import NavigateNextIcon from "@material-ui/icons/NavigateNext";
import Typography from "@material-ui/core/Typography";

function Post() {
  const [selectedPost, setSelectedPost] = useState({
    title: "",
    summary: ""
  });
  const { id } = useParams()

  useEffect(() => {
    axios
      .get(`/api/v1/Post/${id}`, { withCredentials: true })
      .then(function (response) {
        console.log(response);
        setSelectedPost(response.data);
      })
      .catch(function (error) {
        // handle error
        console.log(error.response);
      });
  }, []);

  return (
    <>
      <div className="container">
        <div className="row h-100 flex-nowrap">
          <div className="col pb-5 px-xl-5 mx-auto">
            <div className='post-breadcrumb'>
              <Breadcrumbs
                separator={<NavigateNextIcon fontSize="large" />}
                aria-label="breadcrumb"
              >
                <Link to="/whatsnew">Discover What's New</Link>
                <Typography className="MuiBreadcrumbs-li-last">
                  
                </Typography>
              </Breadcrumbs>
            </div>            
            <h1 className="text-left my-5 title">{selectedPost.title}</h1>
            <div className="row pt-2 banner">
              <Col>
                  {
                    selectedPost.videoUrl ? (<>                                      
                      <video className='postVideo' poster={selectedPost.imageUrl} controls muted autoPlay={true} playsInline ><source src={selectedPost.videoUrl}/></video>
                    </>) : 
                    <img src={selectedPost.imageUrl}/>
                  }
              </Col>
            </div>
            <div className="row pt-2 summary">
              <Col>
                  { parse(selectedPost.summary) }
              </Col>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Post;