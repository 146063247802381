import React, {useEffect, useState} from "react";
import MyPagination from "../../MyPagination"
import MySortedTh, {SortType} from "../../MySortTh"
import { Pages } from "@material-ui/icons"
import './ApprovedUsersTable.css'
import { User } from "../../../Model/Model"
import { List } from "@material-ui/core";

function ApprovedUsersTable(props : any) {
  // let demoUser : User[] = [{id: 1, displayName: 'theDisplayName', title: 'theTitle'}, 
  // {id: 2, displayName: 'theDisplayName2', title: 'theTitle2'} ]
  
  const [theUsers, setTheUsers] = useState<User[]>()
  const [searchKey, setSearchKey] = useState<string>()
  const [sortColumnName, setSortColumnName] = useState<string>("")
  const [sortType, setSortType] = useState<string>("")
  // const theadList : string[] = ["Name", "Title", "Company", "Email", "User Type", "Role", "Edit", "Delete"]

  if(!theUsers && props.data && props.data.length > 0)
  {
    setTheUsers(props.data as User[])
  }
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);

  const changeCurrentPage = (p: number) =>{
    setCurrentPage(p)
  }

  const onPageSizeChange = (p: number) => {
    setPageSize(p)
    setCurrentPage(1)
  }

  const onSearchChange = (e: any) => {
    setSearchKey(e.target.value)
    setCurrentPage(1)
  }

  const getDisplayUsers = () => {
    let theResult = Object.values(Object.assign({}, theUsers || []))
    if(searchKey)
    {
      theResult = theResult?.filter(x => x.email?.toLowerCase().includes(searchKey.toLowerCase()))
    }

    if(sortColumnName)
    {
      if(sortType === SortType.DESC)
      {
        theResult = (theResult as any[]).sort((a, b) => (b[sortColumnName] || "").toString().localeCompare(a[sortColumnName] || ""))
      }
      else{
        theResult = (theResult as any[]).sort((a, b) => (a[sortColumnName] || "").toString().localeCompare(b[sortColumnName] || ""))
      }
    }

    if(totalCount !== theResult.length)
    {
      setTotalCount(theResult.length)
    }
    
    return theResult
  }

  const [totalCount, setTotalCount] = useState<number>()
  // useEffect(()=>{
  //   if(getDisplayUsers())
  //   {
  //     setTotalCount(getDisplayUsers().length)
  //   }
  // }, [totalCount])

  const onThClick = (theSortColumnName: string) => {
    if(!sortColumnName)
    {
      setSortColumnName(theSortColumnName)
      setSortType(SortType.ASC)
    }

    if(theSortColumnName === sortColumnName)
    {
      if(sortType === SortType.DESC)
      {
        setSortType(SortType.ASC)
      }
      else
      {
        setSortType(SortType.DESC)
      }
    }
    else{
      setSortColumnName(theSortColumnName)
      setSortType(SortType.ASC)
    }
  }

  return (
    <>
      <div>
        <div className="button-container float-left"><input className="searchButton" onChange={(e) => onSearchChange(e)} placeholder="Search"></input></div>
        <div className="button-container float-right">
          <button
            className="btn btn-secondary"
            onClick={() => props.handleAddApprovedUsersShow()}
          >
            <i className="fas fa-plus"></i>&nbsp; Add New User
          </button>
          <button
            className="ml-2 btn btn-secondary"
            onClick={() => props.handleAddMultipleUsersShow()}
          >
            <i className="fas fa-plus"></i>&nbsp; Add Multiple Users
          </button>

          {/* <a className="ml-2 btn btn-secondary" href="/api/v1/User/Export/Approved">Export</a> */}
          <button
            className="ml-2 btn btn-secondary"
            onClick={() => props.handleExport()}
          >
            <i className="fas fa-print"></i>&nbsp; Export
          </button>

        </div>
      </div>
      {(getDisplayUsers() && getDisplayUsers().length) 
      ? <div>
      <table className="dataListTbMainList">
        <thead>
          <tr>
            <MySortedTh
              CurrentSortName={sortColumnName}
              SortAble={true}
              SortColumnName={'displayName'}
              SortType={sortType}
              //Style={{ width: '5%', cursor: 'pointer' }}
              Title="Name"
              onThClick={() => onThClick('displayName')}
            />
            <MySortedTh
              CurrentSortName={sortColumnName}
              SortAble={true}
              SortColumnName={'title'}
              SortType={sortType}
              //Style={{ width: '5%', cursor: 'pointer' }}
              Title="Title"
              onThClick={() => onThClick('title')}
            />
            <MySortedTh
              CurrentSortName={sortColumnName}
              SortAble={true}
              SortColumnName={'company'}
              SortType={sortType}
              //Style={{ width: '5%', cursor: 'pointer' }}
              Title="Company"
              onThClick={() => onThClick('company')}
            />
            <MySortedTh
              CurrentSortName={sortColumnName}
              SortAble={true}
              SortColumnName={'email'}
              SortType={sortType}
              //Style={{ width: '5%', cursor: 'pointer' }}
              Title="Email"
              onThClick={() => onThClick('email')}
            />
            <MySortedTh
              CurrentSortName={sortColumnName}
              SortAble={true}
              SortColumnName={'userType'}
              SortType={sortType}
              //Style={{ width: '5%', cursor: 'pointer' }}
              Title="User Type"
              onThClick={() => onThClick('userType')}
            />
            <MySortedTh
              CurrentSortName={sortColumnName}
              SortAble={true}
              SortColumnName={'role'}
              SortType={sortType}
              //Style={{ width: '5%', cursor: 'pointer' }}
              Title="Role"
              onThClick={() => onThClick('role')}
            />
            <th style={{width: "100px"}}>Edit</th>
            <th style={{width: "100px"}}>Delete</th>
          </tr>
        </thead>
        <tbody>
          {getDisplayUsers() && (getDisplayUsers() as User[]).slice((currentPage - 1) * pageSize, currentPage * pageSize) 
          && (getDisplayUsers() as User[]).slice((currentPage - 1) * pageSize, currentPage * pageSize).map((item: User) =>{
            return <tr key={item.id}>
            <td>{item.displayName}</td>
            <td>{item.title}</td>
            <td>{item.company}</td>
            <td>{item.email}</td>
            <td>{item.userType}</td>
            <td>{item.role}</td>
            <td>
            <button
              className="btn btn-primary"
              onClick={() => {
                props.handleUpdateApprovedUsersShow(item);
              }}
            >
              <i className="fas fa-edit"></i>
            </button>
            </td>
            <td>
            <button
              className="btn btn-primary"
              onClick={() => {
                props.handleDeleteApprovedUsersShow(item);
              }}
            >
              <i className="fas fa-trash-alt"></i>
            </button>
            </td>
          </tr>
          }) }
        </tbody>
      </table>
      {totalCount && <MyPagination totalCount={totalCount} onPageSizeChange={(p) => onPageSizeChange(p)} currentPage={currentPage}
      onClick={(p) => changeCurrentPage(p)}/>}
      </div>
      :
      <div className="noResult">There is no result</div>
    }
      
    </>
  );
}

export default ApprovedUsersTable;
