import React, { useState, useEffect } from "react"
import MyPagination from "../../MyPagination"
import MySortedTh, { SortType } from "../../MySortTh"
import { InternalDocumentStorage } from "../../../Model/Model"


function DocumentStorageTable(props: any) {

    const [theData, setTheData] = useState<InternalDocumentStorage[]>()
    const [sortColumnName, setSortColumnName] = useState<string>("")
    const [sortType, setSortType] = useState<string>("")

    useEffect(() => {
        setTheData(props.data as InternalDocumentStorage[]);
        //if (props.data && props.data.length > 0) {
        //    setTheData(props.data as InternalDocumentStorage[]);
        //}
    }, [props.data]);

    const [currentPage, setCurrentPage] = useState(1);
    const [pageSize, setPageSize] = useState(10);

    const changeCurrentPage = (p: number) => {
        setCurrentPage(p)
    }

    const onPageSizeChange = (p: number) => {
        setPageSize(p)
        setCurrentPage(1)
    }

    const getDisplayItems = () => {
        let theResult = Object.values(Object.assign({}, theData || []))
       
        if (sortColumnName) {
            if (sortType === SortType.DESC) {
                theResult = (theResult as any[]).sort((a, b) => (b[sortColumnName] || "").toString().localeCompare(a[sortColumnName] || ""))
            }
            else {
                theResult = (theResult as any[]).sort((a, b) => (a[sortColumnName] || "").toString().localeCompare(b[sortColumnName] || ""))
            }
        }

        if (totalCount !== theResult.length) {
            setTotalCount(theResult.length)
        }

        return theResult
    }

    const [totalCount, setTotalCount] = useState<number>()
    // useEffect(()=>{
    //   if(getDisplayUsers())
    //   {
    //     setTotalCount(getDisplayUsers().length)
    //   }
    // }, [totalCount])

    const onThClick = (theSortColumnName: string) => {
        if (!sortColumnName) {
            setSortColumnName(theSortColumnName)
            setSortType(SortType.ASC)
        }

        if (theSortColumnName === sortColumnName) {
            if (sortType === SortType.DESC) {
                setSortType(SortType.ASC)
            }
            else {
                setSortType(SortType.DESC)
            }
        }
        else {
            setSortColumnName(theSortColumnName)
            setSortType(SortType.ASC)
        }
    }

    return (
        <div>
            <table className="dataListTbMainList">
                <thead>
                    <tr>
                        <MySortedTh
                            CurrentSortName={sortColumnName}
                            SortAble={true}
                            SortColumnName={'title'}
                            SortType={sortType}
                            //Style={{ width: '5%', cursor: 'pointer' }}
                            Title="Title"
                            onThClick={() => onThClick('title')}
                        />
                        <MySortedTh
                            CurrentSortName={sortColumnName}
                            SortAble={true}
                            SortColumnName={'modifiedDate'}
                            SortType={sortType}
                            //Style={{ width: '5%', cursor: 'pointer' }}
                            Title="Modified Date"
                           
                        />
                        
                        <MySortedTh
                            CurrentSortName={sortColumnName}
                            SortAble={true}
                            SortColumnName={'category'}
                            SortType={sortType}
                            //Style={{ width: '5%', cursor: 'pointer' }}
                            Title="Category"
                        />
                        <td> <th style={{ width: "100px" }}>File Link</th> </td>

                        <td>
                            <th style={{ width: "100px" }}>Edit</th>
                        </td>
                        <td>
                            <th style={{ width: "100px" }}>Delete</th>
                        </td>
                       
                    </tr>
                </thead>
                <tbody>
                    {getDisplayItems() && (getDisplayItems() as InternalDocumentStorage[]).slice((currentPage - 1) * pageSize, currentPage * pageSize)
                        && (getDisplayItems() as InternalDocumentStorage[]).slice((currentPage - 1) * pageSize, currentPage * pageSize).map((item: InternalDocumentStorage) => {
                       
                            return <tr key={item.id}>
                                <td>{item.title}</td>
                                <td>{item.modifiedDate}</td>
                               
                                <td>{item.category}</td>
                                <td>
                                    <a href={item.downloadLink} target="_blank" rel="noreferrer">{item.fileName}</a>
                                </td>
                                <td>
                                    {/* edit*/}

                                    <button
                                        className="btn btn-primary"
                                        onClick={() => {
                                            props.handleShow(item);
                                        }}
                                    >
                                        <i className="fas fa-edit"></i>
                                    </button>

                                </td>

                                <td>

                                    {/*delete*/}

                                    <button
                                        className="btn btn-primary"
                                        onClick={() => {
                                            props.handleDeleteShow(item);
                                        }}
                                    >
                                        <i className="fas fa-trash-alt"></i>
                                    </button>

                                </td>
                               
                            </tr>
                        })}
                </tbody>
            </table>
            {totalCount && <MyPagination totalCount={totalCount} onPageSizeChange={(p) => onPageSizeChange(p)} currentPage={currentPage}
                onClick={(p) => changeCurrentPage(p)} />}
        </div>
    );
}

export default DocumentStorageTable;
