import React from 'react'

const MySortedTh = (props: mySortedTh) => {
    return (<th onClick={props.onThClick} style={ props.Style } className={props.ClassName ? props.ClassName : ''}>
    <span>{props.Title}</span>
    {props.SortAble === true && <span className='sortIcons'>
        <span className={((props.CurrentSortName === props.SortColumnName) && (props.SortType === SortType.DESC)) ? 'sortUpDisabled' : 'sortUp'} 
            role="img">
                <i className="fa fa-sort-asc" aria-hidden="true"></i></span>
        <span className={((props.CurrentSortName === props.SortColumnName) && (props.SortType === SortType.ASC)) ? 'sortDownDisabled' : 'sortDown'} 
            role="img">
                <i className="fa fa-sort-desc" aria-hidden="true"></i></span>
    </span>}
    { props.children }
</th>)
}
export default MySortedTh

export interface mySortedTh{
    Title: string, 
    onThClick?: (e: React.MouseEvent | React.KeyboardEvent) => void, 
    SortAble: boolean,
    ClassName?: string,
    SortColumnName: string,
    CurrentSortName: string,
    SortType?: string,
    children?: React.ReactNode,
    Style?: React.CSSProperties
}

export enum SortType{
    ASC = 'ASC',
    DESC = 'DESC'
}