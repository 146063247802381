import React, { useState } from "react"
import ArchiveIcon from '@material-ui/icons/Archive'
import UnarchiveIcon from '@material-ui/icons/Unarchive'
import MyPagination from "../../MyPagination"
import MySortedTh, {SortType} from "../../MySortTh"
import { Post } from "../../../Model/Model"

const ManagePostsTable = (props: any) => {
  const [theUsers, setTheUsers] = useState<Post[]>()
  const [searchKey, setSearchKey] = useState<string>()
  const [sortColumnName, setSortColumnName] = useState<string>("")
  const [sortType, setSortType] = useState<string>("")

  if(theUsers !== props.data)
  {
    setTheUsers(props.data as Post[])
  }
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);

  const changeCurrentPage = (p: number) =>{
    setCurrentPage(p)
  }

  const onPageSizeChange = (p: number) => {
    setPageSize(p)
    setCurrentPage(1)
  }

  const onSearchChange = (e: any) => {
    setSearchKey(e.target.value)
    setCurrentPage(1)
  }

  const getDisplayUsers = () => {
    let theResult = Object.values(Object.assign({}, theUsers || []))
    if(searchKey)
    {
      theResult = theResult?.filter(x => x.title?.toLowerCase().includes(searchKey.toLowerCase()))
    }

    if(sortColumnName)
    {
      if(sortType === SortType.DESC)
      {
        theResult = (theResult as any[]).sort((a, b) => (b[sortColumnName] || "").toString().localeCompare(a[sortColumnName] || ""))
      }
      else{
        theResult = (theResult as any[]).sort((a, b) => (a[sortColumnName] || "").toString().localeCompare(b[sortColumnName] || ""))
      }
    }

    if(totalCount !== theResult.length)
    {
      setTotalCount(theResult.length)
    }
    
    return theResult
  }

  const [totalCount, setTotalCount] = useState<number>()
  // useEffect(()=>{
  //   if(getDisplayUsers())
  //   {
  //     setTotalCount(getDisplayUsers().length)
  //   }
  // }, [totalCount])

  const onThClick = (theSortColumnName: string) => {
    if(!sortColumnName)
    {
      setSortColumnName(theSortColumnName)
      setSortType(SortType.ASC)
    }

    if(theSortColumnName === sortColumnName)
    {
      if(sortType === SortType.DESC)
      {
        setSortType(SortType.ASC)
      }
      else
      {
        setSortType(SortType.DESC)
      }
    }
    else{
      setSortColumnName(theSortColumnName)
      setSortType(SortType.ASC)
    }
  }

  const formatDateTime = (datetime: any) => {
    let date = new Date(datetime);
    let year = new Intl.DateTimeFormat('en', { year: 'numeric' }).format(date);
    let month = new Intl.DateTimeFormat('en', { month: 'short' }).format(date);
    return `${month} ${year}`;
  }

  const formatCategories = (category: []) => {
    return category.join(", ")
  }

  return (
    <>
      <div className="button-container float-left"><input className="searchButton" onChange={(e) => onSearchChange(e)} placeholder="Search"></input></div>
      {
        (getDisplayUsers() && getDisplayUsers().length > 0) 
        ? 
        <div>
          <table className="dataListTbMainList">
            <thead>
              <tr>
                {!props.isBanner && 
                <MySortedTh
                CurrentSortName={sortColumnName}
                SortAble={true}
                SortColumnName={'category'}
                SortType={sortType}
                //Style={{ width: '5%', cursor: 'pointer' }}
                Title="Product Category"
                onThClick={() => onThClick('category')}
              />
                }
                
                <MySortedTh
                  CurrentSortName={sortColumnName}
                  SortAble={true}
                  SortColumnName={'title'}
                  SortType={sortType}
                  //Style={{ width: '5%', cursor: 'pointer' }}
                  Title="Title"
                  onThClick={() => onThClick('title')}
                />
                <MySortedTh
                  CurrentSortName={sortColumnName}
                  SortAble={true}
                  SortColumnName={'createdOn'}
                  SortType={sortType}
                  //Style={{ width: '5%', cursor: 'pointer' }}
                  Title="Date"
                  onThClick={() => onThClick('createdOn')}
                />
                <th style={{width: "100px"}}>Edit</th>
                <th style={{width: "100px"}}>{props.flag? "Unarchive":"Archive"}</th>
              </tr>
            </thead>
            <tbody>
              {getDisplayUsers() && (getDisplayUsers() as Post[]).slice((currentPage - 1) * pageSize, currentPage * pageSize) 
              && (getDisplayUsers() as Post[]).slice((currentPage - 1) * pageSize, currentPage * pageSize).map((item: Post) =>{
                return <tr key={item.id}>
                {!props.isBanner && <td>{item.category}</td>}
                <td>{item.title}</td>
                <td>{formatDateTime(item.createdOn)}</td>
                <td>
                  <button
                    className="btn btn-primary"
                    onClick={() => {
                      props.handleUpdatePost(item);
                    }}
                  >
                    <i className="fas fa-edit"></i>
                  </button>
                </td>
                <td>
                  <button
                    className="btn btn-primary"
                    onClick={() => {
                      props.handleArchivePost(item, props.flag, props.isBanner);
                    }}
                  >
                    {props.flag? (<UnarchiveIcon/>): (<ArchiveIcon/>)}
                  </button>
                </td>
              </tr>
              }) }
            </tbody>
          </table>
          {totalCount && <MyPagination totalCount={totalCount} onPageSizeChange={(p) => onPageSizeChange(p)} currentPage={currentPage}
          onClick={(p) => changeCurrentPage(p)}/>}
        </div>
        : <div className="noResult">There is no result</div>
      }
      
    </>
  );
}

export default ManagePostsTable;
