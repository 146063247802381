import React, {useState} from "react";
import MyPagination from "../../MyPagination"
import MySortedTh, {SortType} from "../../MySortTh"
import { User } from "../../../Model/Model"

const PendingUsersTable = (props: any) => {
  const [theUsers, setTheUsers] = useState<User[]>()
  const [sortColumnName, setSortColumnName] = useState<string>("")
  const [sortType, setSortType] = useState<string>("")

  if(theUsers !== props.data)
  {
    setTheUsers(props.data as User[])
  }
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);

  const changeCurrentPage = (p: number) =>{
    setCurrentPage(p)
  }

  const onPageSizeChange = (p: number) => {
    setPageSize(p)
    setCurrentPage(1)
  }

  const getDisplayUsers = () => {
    let theResult = Object.values(Object.assign({}, theUsers || []))
    if(sortColumnName)
    {
      if(sortType === SortType.DESC)
      {
        theResult = (theResult as any[]).sort((a, b) => (b[sortColumnName] || "").toString().localeCompare(a[sortColumnName] || ""))
      }
      else{
        theResult = (theResult as any[]).sort((a, b) => (a[sortColumnName] || "").toString().localeCompare(b[sortColumnName] || ""))
      }
    }

    if(totalCount !== theResult.length)
    {
      setTotalCount(theResult.length)
    }
    
    return theResult
  }

  const [totalCount, setTotalCount] = useState<number>()
  // useEffect(()=>{
  //   if(getDisplayUsers())
  //   {
  //     setTotalCount(getDisplayUsers().length)
  //   }
  // }, [totalCount])

  const onThClick = (theSortColumnName: string) => {
    if(!sortColumnName)
    {
      setSortColumnName(theSortColumnName)
      setSortType(SortType.ASC)
    }

    if(theSortColumnName === sortColumnName)
    {
      if(sortType === SortType.DESC)
      {
        setSortType(SortType.ASC)
      }
      else
      {
        setSortType(SortType.DESC)
      }
    }
    else{
      setSortColumnName(theSortColumnName)
      setSortType(SortType.ASC)
    }
  }

  // const selectRow = {
  //   mode: 'checkbox',
  //   clickToSelect: true,
  //   onSelect: (row, isSelect, rowIndex, e) => {
  //     props.handleCheckbox(row,isSelect)
  //   },
  //   hideSelectAll: true
  // };

  const checkOnChange = (e: any, theUser: User) => {
    let checkedValue = e.target.checked
    props.handleCheckbox(theUser, checkedValue)
  }

  return (
    <>
    {(getDisplayUsers() && getDisplayUsers().length > 0) ?
    <div>
    <table className="dataListTbMainList">
      <thead>
        <tr>
          <th style={{width: "100px"}}></th>
          <MySortedTh
            CurrentSortName={sortColumnName}
            SortAble={true}
            SortColumnName={'displayName'}
            SortType={sortType}
            //Style={{ width: '5%', cursor: 'pointer' }}
            Title="Name"
            onThClick={() => onThClick('displayName')}
                      />
          <MySortedTh
            CurrentSortName={sortColumnName}
            SortAble={true}
            SortColumnName={'title'}
            SortType={sortType}
            //Style={{ width: '5%', cursor: 'pointer' }}
            Title="Title"
            onThClick={() => onThClick('title')}
          />
          <MySortedTh
            CurrentSortName={sortColumnName}
            SortAble={true}
            SortColumnName={'company'}
            SortType={sortType}
            //Style={{ width: '5%', cursor: 'pointer' }}
            Title="Company Name"
            onThClick={() => onThClick('company')}
          />
          <MySortedTh
            CurrentSortName={sortColumnName}
            SortAble={true}
            SortColumnName={'email'}
            SortType={sortType}
            //Style={{ width: '5%', cursor: 'pointer' }}
            Title="Eemail"
            onThClick={() => onThClick('email')}
          />
          <MySortedTh
            CurrentSortName={sortColumnName}
            SortAble={true}
            SortColumnName={'userType'}
            SortType={sortType}
            //Style={{ width: '5%', cursor: 'pointer' }}
            Title="User Type"
            onThClick={() => onThClick('userType')}
          />
          <MySortedTh
            CurrentSortName={sortColumnName}
            SortAble={true}
            SortColumnName={'role'}
            SortType={sortType}
            //Style={{ width: '5%', cursor: 'pointer' }}
            Title="Account Type"
            onThClick={() => onThClick('role')}
          />
          <MySortedTh
            CurrentSortName={sortColumnName}
            SortAble={true}
            SortColumnName={'role'}
            SortType={sortType}
            //Style={{ width: '5%', cursor: 'pointer' }}
            Title="Requested Client"
            onThClick={() => onThClick('desiredClient')}
          />
          <th style={{width: "100px"}}>Edit</th>
        </tr>
      </thead>
      <tbody>
        {getDisplayUsers() && (getDisplayUsers() as User[]).slice((currentPage - 1) * pageSize, currentPage * pageSize) 
        && (getDisplayUsers() as User[]).slice((currentPage - 1) * pageSize, currentPage * pageSize).map((item: User) =>{
          return <tr key={item.id}>
          <td><input type="checkbox" onChange={(e) => checkOnChange(e, item)}></input></td>
          <td>{item.displayName}</td>
          <td>{item.title}</td>
          <td>{item.company}</td>
          <td>{item.email}</td>
          <td>{item.userType}</td>
          <td>{item.role}</td>
          <td>{item.desiredClient}</td>
          <td>
            <div>
              <button
                className="btn btn-primary"
                onClick={() => {
                  props.handleShow(item);
                }}
              >
                <i className="fas fa-edit"></i>
              </button>
            </div>
          </td>
        </tr>
        }) }
      </tbody>
    </table>
      {totalCount && <MyPagination totalCount={totalCount} onPageSizeChange={(p) => onPageSizeChange(p)} currentPage={currentPage}
      onClick={(p) => changeCurrentPage(p)}/>}
  </div>
  :
  <div>There is no result</div>
     }
      
    </>
  );
}

export default PendingUsersTable;
